.ui.modal {
  overflow: auto !important;
  box-sizing: border-box;
  max-height: calc(100vh - 2rem);
}

.c-modal-overlay {
  bottom: 0;
  max-height: none;

  --footer-height: 4.643rem;

  > .content.content.content {
    min-height: 0;
    margin-bottom: auto;
    position: absolute;

    &::after {
      content: '';
      display: block;
      margin-bottom: calc(var(--footer-height) - 1rem);
    }
  }

  > .actions {
    position: absolute;
    top: calc(100vh - var(--footer-height));
    width: 100%;
    left: 0;
  }
}

.ui.modal.overlay.fullscreen {
  .c-modal-overlay;
}

.ui.modal:not(.fullscreen) {
  @media only screen and (max-width: @largestMobileScreen) {
    width: 100%;
    left: 0;
    margin: 0 auto;
    top: 0;
    border-radius: 0;

    > :first-child:not(.close, .dimmer),
    > i.icon:first-child + *,
    > .dimmer:first-child + *:not(.close),
    > .dimmer:first-child + i.icon + * {
      border-radius: 0;
    }

    .c-modal-overlay;
  }
}
