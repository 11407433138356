
@cCardStripeWidth: unit((20 / @emSize), rem);
@cCardBoxShadow: 0 0 8px #bbb;

@cCardWidth: unit((323 / @emSize), rem);
@cCardGap: unit((3.5 / @emSize), rem);
@cCardPadding: unit((10 / @emSize), em) unit((5 / @emSize), em);

@cSmallCardStripeWidth: unit((10 / @emSize), rem);
@cSmallCardWidth: unit((158 / @emSize), rem);
@cSmallCardGap: unit((3 / @emSize), rem);
@cSmallCardPadding: unit((4 / @emSize), em);

& when not (@variationCardColors = false) {
  each(@variationCardColors, {
    @color: @value;
    @c: @colors[@@color][color];
    @h: @colors[@@color][hover];
    @l: @colors[@@color][light];
    @lh: @colors[@@color][lightHover];
    @isVeryDark: @colors[@@color][isVeryDark];

    .ui.@{color}.cards > .card,
    .ui.cards > .@{color}.card,
    .ui.@{color}.card {
      box-shadow: @cCardBoxShadow;
      padding-left: @cCardStripeWidth;

      &:before {
        content: '';
        width: @cCardStripeWidth;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background: @c;
        border-top-left-radius: @borderRadius;
        border-bottom-left-radius: @borderRadius;
      }
    }

    & when (@variationCardLink) {
      .ui.cards a.@{color}.card:hover,
      .ui.@{color}.cards a.card:hover,
      .ui.link.@{color}.cards .card:not(.icon):hover,
      .ui.link.cards .@{color}.card:not(.icon):hover,
      a.ui.@{color}.card:hover,
      .ui.link.@{color}.card:hover {
        box-shadow: @cCardBoxShadow;

        &:before {
          background: @h;
        }
      }
    }

    .ui.@{color}.cards.c-cards-small > .card,
    .ui.cards.c-cards-small > .@{color}.card {
      padding-left: @cSmallCardStripeWidth;
      &:before {
        width: @cSmallCardStripeWidth;
      }
    }
  });
}

.ui.cards.EntityCardList {
  margin: @cCardGap * -1;
}

.ui.cards.EntityCardList > .ui.card {
  box-shadow: @cCardBoxShadow;
  width: @cCardWidth;
  margin: @cCardGap;

  > .content {
    padding: @cCardPadding;

    > .header:not(.ui) {
      margin-top: 0;
      font-size: 1em;
      font-weight: normal;
      color: inherit;
    }

    > .meta:not(.ui) {
      font-size: 0.8em;
    }
  }
}

.ui.cards.c-cards-small {
  margin: @cSmallCardGap * -1;

  > .card {
    font-size: 0.8em;
    width: @cSmallCardWidth;
    margin: @cSmallCardGap;

    > .content {
      padding: @cSmallCardPadding;
      font-size: 0.8em;
    }
  }
}
