
nav.labeled.icon.menu.c-icon-menu {
  overflow: visible;
  display: inline-flex;
  width: min-content;
  @color: primary;

  &:first-child {
    margin-top: 20px;
  }

  > .item {
    min-width: 2em!important;
    padding-left: 0.6em;
    padding-right: 0.6em;
    z-index: 1;
  }

  > .item.active, > .item.active:hover {
    z-index: 0;
    background-color: @colors[@@color][color];
    color: @colors[@@color][text];
    margin-top: 0;
  }

  > .item:hover {
    color: @colors[@@color][color];
  }
}
