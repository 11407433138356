.ui.grid .column.c-min-content {
  width: min-content;
}

.ui.grid.c-flex-spacing-small {
  @spaceing: unit((@rowSpacing / 2));

  margin: (@spaceing * -0.5em) !important;

  .column:not(.row) {
    padding: @spaceing * 0.5em !important;
  }
}


main {
  &.popup {
    &:not(.responsive, .popup-full) {
      min-width: 1000px;

      .ui.ui.ui.container:not(.fluid) {
        margin-left: 0;
        margin-right: 0;
        width: auto;
      }

      @media only screen and (max-width: @largestMobileScreen) {
        .ui.ui.ui.container:not(.fluid) {
          width: auto;
          margin-left: 0;
          margin-right: 0;
        }
      }

      /* Tablet */
      @media only screen and (min-width: @tabletBreakpoint) and (max-width: @largestTabletScreen) {
        .ui.ui.ui.container:not(.fluid) {
          width: auto;
          margin-left: 0;
          margin-right: 0;
        }
      }

      /* Small Monitor */
      @media only screen and (min-width: @computerBreakpoint) and (max-width: @largestSmallMonitor) {
        .ui.ui.ui.container:not(.fluid) {
          width: auto;
          margin-left: 0;
          margin-right: 0;
        }
      }

      @media only screen and (min-width: @largeMonitorBreakpoint) {
        .ui.ui.ui.container:not(.fluid) {
          width: auto;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
