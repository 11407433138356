.ui.connected.feed {
  &.c-label-left {
    @left: 6em;

    margin-left: @left;

    @media only screen and (max-width: @largestMobileScreen) {
      margin-left: @left * 0.85;
    }

    > .event {
      > .label {
        font-size: 1.1em;
      }

      > .content .summary > .date.floated.left {
        position: absolute;
        left: -1.6em;
        top: 0.5em;
        transform: translateX(-100%);
        max-width: @left;
        text-align: right;
        line-height: 1.1em;
      }
    }
  }

  > .event {
    &:not(:last-child)::before {
      top: 3.1em;
      border-left-width: 1px;
      height: calc(100% - 3.5em);
    }
  }
}
