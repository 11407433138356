.ui.tertiary.button, .ui.tertiary.primary.button {
  &:active, &:focus, &:hover {
    box-shadow: none;
    background: none;
  }

  &:active {
    color: @blue;
  }

  &:focus {
    color: @darkGrey;
  }
}

.ui.inverted.primary.button {
  background-color: @white;
  color: @grey;
  box-shadow: transparent 0 0 0 1px inset;

  &:active, &:focus, &:hover {
    background-color: #f2f2f2;
    color: #4d4d4d;
  }
}
