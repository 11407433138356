
.ui.table:not(.unstackable) > tbody > tr > td:first-child {
  font-weight: inherit;
}

.td-multi-lines {
  span {
    display: inline-block;
  }
}
