html,
input,
select,
textarea,
table {
  * {
    box-sizing: border-box;
  }
}

form {
  margin: 0;

  &.shadowed {
    box-shadow: 0 0 10px #ccc;
  }
}

a {
  &,
  &:visited {
    color: #888;
    text-decoration: none;
  }

  &.active,
  &.active:hover {
    background: @primaryColor;
    color: white;
  }

  &.no-active {
    background: transparent;

    &:hover {
      background: #888;
      color: white;
    }
  }

  &.never-active {
    background: #888;
    color: white;

    &:hover {
      background: @primaryColor;
      color: white;
    }
  }

  &.active,
  &.no-active,
  &.never-active {
    padding: 2px 10px;
    margin-top: 4px;
    border-radius: 4px;
    display: inline-block;
  }

  &:hover {
    color: @primaryColor;
  }
}

hr {
  border: 0;
  border-top: 1px solid #ddd;

  &.dark {
    border-color: #ccc;
  }
}

b,
strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  color: #444;

  &.subtitle {
    padding: 0 16px;
  }

  &:last-child, &:first-child {
    margin: @headerMargin;
  }
}

a.subtitle {
  font-size: 75%;
}

span.subtitle {
  font-size: 80%;
  color: #888;
}

p {
  &.group {
    margin: 0;
    padding: 0;
  }

  &.subtitle {
    color: #888;
    font-size: 80%;
  }

  &.notice {
    font-size: 130%;
    font-weight: 300;
    color: #888;
    text-align: center;
  }
}

ul:not(.ui.list) {
  margin: 0;
  padding: 0 0 0 20px;

  li {
    list-style-type: none;
    padding: 0 0 0 10px;
    border-left: 1px solid #bbb;
    margin: 0;
  }
}

button, input, optgroup, select, textarea {
  margin: 3px;
}
